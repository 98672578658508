<template>

  <TemplateModal ref="modal" title="Rejestracja">
    <template #default>

      <AuthRegisterForm
        :register_data="register_data"
        @registerSubmit="registerSubmit"
        @emitLogin="$emit('emitLogin')"
      />

    </template>
  </TemplateModal>

</template>

<script>
import TemplateModal from "@/views/Components/TemplateModal.vue";
import AuthRegisterForm from "@/views/Auth/Part/RegisterForm.vue";
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine";
import ControllForm from "@/Library/Controll/Form.vue";
import { useToast } from "vue-toastification";
import axios from "@/axios";
import View from "@/views/View.vue"

export default {
  name: "AuthRegisterModal",
  components: {
    AuthRegisterForm,
    TemplateModal
  },
  extends: View,
  emits: ["emitLogin"],
  mixins: [ComponentFormStateMachine, ControllForm],
  data: () => ({
    register_data: {
      mail: null,
      pass: null,
      pass_again: null
    }
  }),
  methods: {
    open() {
      this.$refs.modal.open()
    },

    close() {
      this.$refs.modal.close()
    },

    registerSubmit() {

      this.stateUpdate('sendInfo');

      // if(!this.$refs.form.validateSuccess()){
      //   return false;
      // }

      let data = this.register_data

      const toast = useToast();
      axios
        .post('user/register', data)
        .then(response => {

          console.log(response.data)

          alert("Konto zostało założone, Link aktywacyjny został wysłany na podany adres mail");

          this.stateUpdate('sendSuccess');

          this.close();

        })
        .catch(error => {

          this.stateUpdate('sendWrong');

          let error_data = error.response.data;
          let error_status = error.response.status;
          let error_header = error.response.headers;
          let error_code = error_data.code;

          console.log(error_data);
          console.log(error_status);
          console.log(error_header);

          switch (error_code) {
            case 'no-required-data':
              this.errorMessage = 'Brak wymaganych danych.'
              break;
            case 'object-exist':
              this.errorMessage = 'Błędne dane'
              break;
            case 'pass-and-pass-again-not-equal':
              this.errorMessage = 'Hasło i powtórz hasło są różne'
              break;
            default:
              this.errorMessage = 'Wystąpił błąd'
              break;
          }

alert(this.errorMessage);

        })

    }
  }
}
</script>

<style scoped lang="scss">

.register {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-height: 100%;

  &__form {

    & > p:last-child {
      margin-top: 20px;
      text-align: end;
      cursor: pointer;
      color: rgb(73, 78, 155);
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: auto;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 14px 28px;
  border-radius: 0.25rem;
  background-color: rgb(73, 78, 155);
  color: #fff;
  cursor: pointer;

  &--create {
    background-color: #42855B;
  }
}

</style>