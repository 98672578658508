<template>

    <TemplateModal ref="modal" title="Logowanie">
        <template #default>

            <AuthLoginForm
                :login_data="login_data"
                @loginSubmit="loginSubmit"
                @emitForgotPassword="$emit('emitForgotPassword')"
                @emitRegister="$emit('emitRegister')"/>

        </template>
    </TemplateModal>

</template>

<script>
import TemplateModal from "@/views/Components/TemplateModal.vue";
import AuthLoginForm from "@/views/Auth/Part/LoginForm.vue";
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine";
import {useToast} from "vue-toastification";
import {mapActions} from 'vuex'
import axios from "@/axios";
import View from "@/views/View.vue"

export default {
    name: "AuthLoginModal",
    components: {
        AuthLoginForm,
        TemplateModal
    },
    extends: View,
    emits: ["emitForgotPassword", "emitRegister"],
    mixins: [ComponentFormStateMachine],
    data: () => ({
        login_data: {
            // mail: "biuro@strefaportali.pl",
            // pass: "biuro@strefaportali.pl"
        }
    }),
    methods: {
        open() {
            this.$refs.modal.open()
        },

        close() {
            this.$refs.modal.close()
        },

        /**
         * Dołączanie metod z pliku '@/store/user.js' (moduł `user`)
         */
        ...mapActions({
            login: 'user/login',
            getUserInfo: 'user/getUserInfo'
        }),

        consoleError(error) {
            console.error(error)

            if (error.response) {

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);
                console.log(error_code);
            }
        },

        async loginSubmit() {

            this.stateUpdate('sendInfo');

            const toast = useToast();

            let data = this.login_data

            await axios
                .post(
                    'user/auth',
                    data
                )
                .then(async response => {

                    this.close()


                    toast.success("Logowanie poprawne", {
                        timeout: 2000
                    });


                    let data = response.data;

                    let row = data.data.token;


                    this.login({data: row});

                    await this.getUserInfo();

                    this.stateUpdate('sendSuccess');

                    this.$router.push('/profile/lessons');

                })
                .catch(error => {
                    alert("Niepoprawne dane")


                    this.stateUpdate('sendWrong');

                    this.consoleError(error);

                    let error_code = error.response ? error.response.data.code : null;

                    switch (error_code) {
                        case 'no-required-data':
                            this.errorMessage = 'Brak wymaganych danych.'
                            break;
                        case 'object-no-exist':
                            this.errorMessage = 'Błędny adres mail lub hasło'
                            break;
                        default:
                            this.errorMessage = 'Brak dostępu'
                            break;
                    }

                    toast.error(this.errorMessage, {
                        timeout: 2000
                    });

                })

        }
    }
}
</script>

<style scoped lang="scss">

</style>