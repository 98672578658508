<template>

  <div :class="'alert alert-'+typeBts" role="alert">
    <div class="alert-body" v-if="show_label"><strong>{{ typeLabel }}:</strong> <span v-html="text"></span>
      <slot></slot>
    </div>
    <div class="alert-body" v-if="!show_label">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    type: {
      default() {
        return 'info';
      }
    },
    text: {
      default() {
        return '';
      }
    },
    label: {
      default() {
        return null;
      }
    },
    show_label: {
      default() {
        return true;
      }
    }
  },
  computed: {
    typeBts() {

      let ret = this.type;

      // if(this.type === 'error')
      //   ret = 'danger'

      return ret;

    },
    typeLabel() {
      let ret = null;
      if(this.type === 'info')
        ret = 'Info';
      if(this.type === 'success')
        ret = 'Udało się';
      if(this.type === 'warning')
        ret = 'Ostrzeżenie';
      if(this.type === 'error')
        ret = 'Wystąpił błąd';

      return ret;

    }
  }
}
</script>


<style scoped lang="scss">
.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &-info{
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb;
  }
  &-success{
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
  }
  &-warning{
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
  }
  &-error{

    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }
}
</style>